import React, { useContext, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";
import { Box } from "@mui/material";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
// import TicketOptionsMenu from "../TicketOptionsMenu";
import TransferTicketModal from "../TransferTicketModal";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const http = require('http');

const init = {
	host: 'localhost',
	path: '/zdgProtocolo',
	port: 8080,
	method: 'POST',
	headers: {
		'content-type': 'application/json; charset=utf-8'
	}
};

const init2 = {
	host: 'localhost',
	path: '/zdgSendEmail',
	port: 8080,
	method: 'POST',
	headers: {
		'content-type': 'application/json; charset=utf-8'
	}
};

const callback = function (response) {
	let result = Buffer.alloc(0);
	response.on('data', function (chunk) {
		result = Buffer.concat([result, chunk]);
	});

	response.on('end', function () {
		console.log(result.toString());
	});
};

async function ZDGProtocolo(usuario, protocolo) {
	const req = http.request(init, callback);
	const body = `{"usuario":"` + usuario + `","protocolo":"` + protocolo + `"}`;
	await req.write(body);
	req.end();
}

async function ZDGEmail(zdgSubject, zdgText) {
	const req = http.request(init2, callback);
	const body = `{"zdgSubject":"` + zdgSubject + `","zdgText":"` + zdgText.replace(/\n/g, "\\n") + `"}`;
	await req.write(body);
	req.end();
}

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const { ticketId } = useParams();
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	// const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const date = new Date();
	// const minutes = date.getMinutes();
	// const hour = date.getHours();

	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);

	const handleSendMessage = async (status, userId) => {
		const message = {
			read: 1,
			fromMe: true,
			mediaUrl: "",
			body: "Seu protocolo de atendimento é: *nº " + Math.floor(Date.now() / 1000) + '*',
		};
		try {
			// const { data } = await api.get("/tickets/" + ticketId);
			// ZDGProtocolo(data.contact.number, Math.floor(Date.now() / 1000).toString());
			await api.post(`/messages/${ticketId}`, message);
			alert('Protocolo de atendimento:' + Math.floor(Date.now() / 1000));
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});
			// ZDGEmail('Ticket ' + ticketId + ' acaba de ser finalizado.', 'O atendente acaba de fechar o ticket nº ' + ticketId + '\nNúmero de contato: ' + data.contact.number + '\nNome ' + data.contact.name + '\nHorário ' + hour + ':' + minutes)
		} catch (err) {
			toastError(err);
		}
	};

	// const handleOpenTicketOptionsMenu = e => {
	// 	setAnchorEl(e.currentTarget);
	// };

	// const handleCloseTicketOptionsMenu = e => {
	// 	setAnchorEl(null);
	// };

	const handleUpdateTicketStatus = async (e, status, userId, text) => {
		setLoading(true);
		try {
			if (status === "closed" && text === "Protocolo") {
				handleSendMessage(status, userId);
			}
			else if (status === "closed" || text === "Protocolo") {
				try {
					const { data } = await api.get("/tickets/" + ticketId);
					// ZDGEmail('Ticket ' + ticketId + ' acaba de ser finalizado.', 'O atendente acaba de fechar o ticket nº ' + ticketId + '\nNúmero de contato: ' + data.contact.number + '\nNome ' + data.contact.name + '\nHorário ' + hour + ':' + minutes);
					await api.put(`/tickets/${ticket.id}`, {
						status: status,
						userId: userId || null,
					});
				} catch (err) {
					toastError(err);
				}
			}
			else {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null,
				});
			}

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			}
			else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticket.id) return;
		setLoading(true);
		try {
			let data = {};

			data.queueId = ticket.queueId
			data.userId = user.id;
			data.leadId = ticket.lead_icode;
			data.observacao = ticket.observacao;
			data.name = ticket.name;

			await api.put(`/ticketsetapa/${ticket.id}`, data);

			setLoading(false);
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		anchorEl();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	return (
		<div className={classes.actionButtons}>
			<Box
				display="flex"
				flexWrap="wrap"
				justifyContent="center"
				gap={1}
				sx={{ width: "100%", maxWidth: 500, mx: "auto" }}
			>
				{ticket.status === "closed" && (
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id, "")}
					>
						{i18n.t("messagesList.header.buttons.reopen")}
					</ButtonWithSpinner>
				)}
				{ticket.status === "open" && (
					<>
						{/*<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null, "")}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id, "")}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id, "Protocolo")}
					>
						{"Protocolo"}
					</ButtonWithSpinner>*/}
						{user.id != ticket.userId && (
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="secondary"
								onClick={handleSaveTicket}
							>
								Assumir
							</ButtonWithSpinner>
						)}
						<ButtonWithSpinner
							loading={loading}
							size="small"
							variant="contained"
							color="primary"
							onClick={handleOpenTransferModal}
						>
							Alterar
						</ButtonWithSpinner>

						{ticket.lead_icode && (
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="success"
								onClick={() => {
									window.open(
										localStorage.getItem("url_retorno_crm") + "/app/crm/lead/form.xhtml?lead=" + ticket.lead_icode,
										"_blank"
									);
								}}
							>
								Acessar
							</ButtonWithSpinner>

						)}

						{/*<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
				/>*/}
					</>
				)}
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id,)}
					>
						{i18n.t("messagesList.header.buttons.accept")}
					</ButtonWithSpinner>
				)}
				<TransferTicketModal
					modalOpen={transferTicketModalOpen}
					onClose={handleCloseTransferTicketModal}
					ticketid={ticket.id}
					userId={user.id}
					filialId={ticket?.user?.filial_icode}
					leadId={ticket.lead_icode}
					nomeT={ticket.contact.name}
					obs={ticket.observacao}
					agenda={ticket.agendamento}
					etapa={ticket.queueId}
					tag={ticket.tagId}
				/>
			</Box>
		</div >
	);
};

export default TicketActionButtons;
