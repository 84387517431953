import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const TicketsSemRespostaSelect = ({ selectedTime, onChange }) => {
	const [time, setTime] = useState(selectedTime || "");

	useEffect(() => {
		setTime(selectedTime || "");
	}, [selectedTime]);

	const handleChange = e => {
		setTime(e.target.value);
		if (onChange) onChange(e.target.value);
	};

	return (
		<div>
			<FormControl fullWidth margin="dense">
				<Select
					displayEmpty
					variant="outlined"
					value={time}
					onChange={handleChange}
					renderValue={(selected) => selected || "Tempo sem resposta"}
				>
					<MenuItem value="">
						<em>Sem seleção</em>
					</MenuItem>
					<MenuItem value="10m">10m</MenuItem>
					<MenuItem value="20m">20m</MenuItem>
					<MenuItem value="30m">30m</MenuItem>
					<MenuItem value="12h">12h</MenuItem>
					<MenuItem value="24h">24h</MenuItem>
					<MenuItem value="48h">48h</MenuItem>
					<MenuItem value="Não respondido">Não respondido</MenuItem>
				</Select>
			</FormControl>
		</div>
	);

};

export default TicketsSemRespostaSelect;
