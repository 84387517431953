import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketsQueueSelect from "../TicketsQueueSelect";
import TicketsTagSelect from "../TicketsTagSelect";
import TicketsSemRespostaSelect from "../TicketsSemRespostaSelect";
import TicketsWhatsappSelect from "../TicketsWhatsappSelect";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
}));

const TicketFiltersModal = ({ modalOpen, onClose, user, resetFilter }) => {
	const [dataInicio, setDataInicio] = useState("");
	const [dataFim, setDataFim] = useState("");
	const userQueueIds = user.queues.map((q) => q.id);
	const userWhatsappIds = user.whatsapps.map((w) => w.id);
	const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
	const [selectedTagId, setSelectedTagId] = useState("");
	const [selectedWhatsappIds, setSelectedWhatsappIds] = useState(userWhatsappIds || []);
	const [selectedTime, setSelectedTime] = useState("");

	const classes = useStyles();

	const handleClose = () => {
		onClose({ dataInicio, dataFim, selectedQueueIds, selectedTagId, selectedWhatsappIds, selectedTime });
	};

	const handleLimpaFiltro = () => {
		setDataInicio("");
		setDataFim("");
		setSelectedQueueIds(userQueueIds || []);
		setSelectedTagId("");
		setSelectedWhatsappIds(userWhatsappIds || []);
		setSelectedTime("");
	};

	const handleCancelTicket = () => {
		handleClose();
	};

	useEffect(() => {
		console.log(resetFilter);
		if (resetFilter) {
			handleLimpaFiltro();
		}
	}, [modalOpen]);

	return (
		<Dialog open={modalOpen} onClose={handleClose}>
			<DialogTitle id="form-dialog-title">
				Filtrar lead
			</DialogTitle>
			<DialogContent dividers>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TextField
						label="Data inicial"
						variant="outlined"
						type="date"
						fullWidth
						InputLabelProps={{ shrink: true }}
						className={classes.marginTop}
						value={dataInicio}
						onChange={(e) => setDataInicio(e.target.value)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TextField
						label="Data final"
						variant="outlined"
						type="date"
						fullWidth
						InputLabelProps={{ shrink: true }}
						className={classes.marginTop}
						value={dataFim}
						onChange={(e) => setDataFim(e.target.value)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TicketsWhatsappSelect
						style={{ marginLeft: 6 }}
						selectedWhatsAppIds={selectedWhatsappIds}
						whatsApps={user?.whatsapps}
						onChange={(values) => setSelectedWhatsappIds(values)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TicketsQueueSelect
						style={{ marginLeft: 6 }}
						selectedQueueIds={selectedQueueIds}
						userQueues={user?.queues}
						onChange={(values) => setSelectedQueueIds(values)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TicketsTagSelect
						style={{ marginLeft: 6 }}
						filialId={user.filial_icode}
						userId={user.id}
						tag={selectedTagId}
						onChange={(values) => setSelectedTagId(values)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TicketsSemRespostaSelect
						style={{ marginLeft: 6 }}
						selectedTime={selectedTime}
						onChange={(value) => setSelectedTime(value)}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleCancelTicket()}
					color="secondary"
					variant="outlined"
				>
					Cancelar
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					onClick={() => handleClose()}
					color="primary"
				>
					Filtrar
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default TicketFiltersModal;
