import React, { useState, useEffect } from "react";

const Timer = ({ startDate }) => {
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    if (!startDate) return;

    const startTimestamp = new Date(startDate).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const elapsed = Math.floor((now - startTimestamp) / 1000);
      setTimeElapsed(elapsed);
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      {formatTime(timeElapsed)}
    </div>
  );
};

export default Timer;
